<template>
  <v-img
    v-if="namespace === 'Home'"
    :height="$vuetify.breakpoint.xsOnly ? '100vh' : '70vh'"
    gradient="to top, rgba(255, 255, 255, .75), rgba(255, 255, 255, .75)"
    src="/static/e1.jpg"
  >
    <v-container
      fill-height
      fluid
      :px-12="$vuetify.breakpoint.mdAndUp"
    >
      <v-row align-center>
        <v-slide-x-transition appear>
          <v-col>
            <h1
              class="mb-4"
              :class="[$vuetify.breakpoint.xsOnly ? 'display-1' : 'display-3']"
              style="font-family:'Kanit'!important"
            >
              <span style="font-family:'Skia'!important"><b>X T E R R A </b></span>
              <sup style="font-size: 3.5rem;"><span style="font-size: 1.25rem;">®</span></sup>
            </h1>

            <div
              class="mb-4"
              :class="[$vuetify.breakpoint.xsOnly ? 'headline' : 'display-1 font-weight-light']"
              style="font-family:'Kanit'!important"
            >
              ENERGY & REAL ESTATE <strong style="font-size: 1.5rem;">CONSULTING</strong>
            </div>

            <v-responsive
              max-width="600"
              class="mb-5 font-weight-light"
              style="font-family:'Kanit'!important;font-size:1.5em"
            >
              PERFORMANCE PERFECTION QUALITY™
            </v-responsive>
          </v-col>
        </v-slide-x-transition>
      </v-row>
    </v-container>
  </v-img>

  <v-img
    v-else
    :min-height="$vuetify.breakpoint.smAndDown ? '35vh' : '250px'"
    gradient="to top, rgba(255, 255, 255, .85), rgba(255, 255, 255, .85)"
    src="/static/e1.jpg"
    height="20vh"
  >
    <v-container fill-height>
      <v-row
        align-content="center"
        justify="center"
        class="fill-height"
      >
        <v-col cols="12">
          <h1
            class="display-1 text-center"
            style="font-family:'Kanit'!important"
            v-text="title"
          />
        </v-col>

        <v-breadcrumbs
          :items="breadcrumbs"
          class="grey--text pb-0 px-3"
          style="font-family:'Kanit'!important"
        />
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
  export default {
    computed: {
      breadcrumbs () {
        const home = { text: 'HOME', to: '/' }

        switch (this.namespace) {
          case 'About': return [home, { text: 'ABOUT US' }]
          case 'Services': return [home, { text: 'SERVICES' }]
          case 'Contact': return [home, { text: 'CONTACT' }]
          default: return []
        }
      },
      namespace () {
        return this.$route.name
      },
      title () {
        switch (this.namespace) {
          case 'About': return 'XTERRA CONSULTING'
          case 'Services': return 'SERVICES'
          case 'Contact': return 'CONTACT'
          default: return ''
        }
      }
    }
  }
</script>
